import React, { useEffect, useState, useContext } from "react"
import * as styles from "./detail.module.css"
import Layout from "components/common/layout.js"
import queryString from "query-string"
import api from "apis"
//

import dompurify from "dompurify"
import Lesson from "components/post/lesson.js"
import Slider from "components/tools/slide/Slide.js"
import DetailHeader from "components/post/detailHeader"
import modalContext from "context/modalContext"
import { onFavor, offFavor, lessonHeader, postHit, postView } from "images"
import {
  getDateYMD,
  getThumb,
  onLike,
  onShare,
  download,
  // getNewTag,
} from "utils"
import smallShare from "images/smallShare.png"
import Comment from "containers/lesson/comment.js"
import { smallSliceIcon } from "images"
import { navigate } from "gatsby-link"
import InputTitle from "components/tools/InputTitle.js"
import { permission } from "utils"
import { getRemoveUI } from "utils"
import NewTag from "components/tools/newTag"
import Like from "components/tools/Like"
const Detail = props => {
  const [post, setPost] = useState({})

  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const { number } = queryString.parse(props.location.search)

  useEffect(() => {
    ;(async () => {
      const post = await api.lessonDetail_test(number)
      if (post) setPost(post)
    })()
  }, [number])

  const nanum_curriculum = post.parse_curriculum?.map((tag, i) => (
    <div
      style={{
        backgroundColor: `${color.get(tag)}`,
      }}
      key={i}
    >
      {tag}
    </div>
  ))

  console.log(post)

  const nanum_course = `${post.parse_course?.join() ?? ""}을 위한 수업`

  const nanum_kind = post.parse_kind?.map((v, i) => (
    <div key={i} style={{ textAlign: "center" }}>
      {v}
    </div>
  ))
  return (
    <Layout>
      <DetailHeader
        left={
          <img
            className={styles.leftImg}
            style={{
              maxWidth: "350px",
              minWidth: "350px",
            }}
            src={post.story?.img}
          />
        }
        right={
          <div className={styles.rightBox}>
            <div className={styles.curriculum}>
              {nanum_curriculum}
              <NewTag registerTime={post?.registerTime} />
            </div>
            <div className={styles.title}>{post.title}</div>
            <div className={styles.course}>{nanum_course}</div>
            <div className={styles.kind}>{nanum_kind}</div>
            <div
              className={styles.storyIntro}
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/story/detail/?number=${post.db?.Story?.story_no}`)
              }
            >
              <img
                src={lessonHeader}
                style={{ width: "30px", height: "30px" }}
              />
              {post.db?.Story?.title}
            </div>
            <div className={styles.info}>
              <div className={styles.user}>
                <img src={post.writerProfile?.icon} alt="" />
                <div>{post.writerProfile?.name}</div>
                <div>{post.registerTime}</div>
              </div>
              <div className={styles.post}>
                <div className={styles.view}>
                  <div>
                    view
                    {/* <img src={postView} width="35px" height="24px" /> */}
                  </div>
                  <div>{post.hit}</div>
                </div>
                <div className={styles.etc}>
                  <div>
                    {/* <img
                      src={post.Like ? onFavor : offFavor}
                      onClick={e => onLike(e, post.nanum_no, "nanum")}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <div>{post.like}</div> */}
                    <Like
                      on={onFavor}
                      off={postHit}
                      keyword={"nanum"}
                      isLike={post?.Like}
                      style={{ count: { marginLeft: "3px" } }}
                      postNumber={number}
                      count={post?.like}
                    />
                  </div>
                  <img
                    src={smallShare}
                    onClick={async () => {
                      const post = await api.lessonDetail_test(number)
                      if (post) setPost(post)

                      onShare(setmodalOpen, setModalMain)
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      >
        {typeof window !== "undefined" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(post.bodyText),
            }}
            style={{
              textAlign: "left",
              minHeight: "100px",
              minWidth: "780px",
              maxWidth: "780px",
            }}
          />
        ) : null}
        <div className={styles.fileCon}>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "500",
              letterSpacing: "-0.43px",
              color: "#333",
            }}
          >
            첨부자료
          </div>
          <div>
            {post.fileList?.map((file, i) => (
              <div key={i}>
                <div
                  onClick={() => {
                    file.download()
                  }}
                >
                  {file.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </DetailHeader>

      {post.isComment ? <Comment post={post} /> : null}
      <div className={styles.Btn}>
        <div>
          <div
            className="greenBtn"
            onClick={() => {
              permission("/class/lesson/write/")
            }}
          >
            수업나눔 작성하기
          </div>
          {/* 작성자일때만 보여주기 */}
          {post.isMine ? (
            <>
              <div
                className="greenBtn"
                onClick={() =>
                  navigate(`/class/lesson/write?number=${post.nanum_no}`)
                }
              >
                수정
              </div>
              <div
                className="greenBtn"
                onClick={() => {
                  getRemoveUI(
                    setModalMain,
                    setmodalOpen,
                    "수업나눔을 정말 삭제하시겠습니까?",
                    async () => {
                      const result = await api.lessonRemove({
                        body: {
                          nanum_number: post.nanum_no,
                        },
                      })
                      if (result) {
                        navigate("/class/lesson/list/")
                      } else {
                        alert("잠시 후 다시 시도해주세요")
                      }
                    }
                  )
                }}
              >
                삭제
              </div>
            </>
          ) : null}
        </div>
        <div
          className="greenBtn"
          onClick={() => navigate("/class/lesson/list/")}
        >
          목록
        </div>
      </div>
      {post.accStory?.length > 0 ? (
        <>
          <div className={styles.slideTitle}>
            <InputTitle
              name={`${post.writerProfile?.name}님의 수업나눔 보러가기`}
              titleSize={22}
            />
            <div
              onClick={() => navigate("/class/lesson/list/")}
              className={styles.view}
            >
              <div>전체보기</div>
              <img src={smallSliceIcon} />
            </div>
          </div>
          <div className={styles.slideCon}>
            <Slider name={"associated"}>
              {/* accStory */}
              {post.accStory?.map((post, i) => (
                <Lesson post={post} key={i} />
              ))}
            </Slider>
          </div>
        </>
      ) : null}
    </Layout>
  )
}

export default Detail

const color = new Map([
  ["국어", "#8EACD9"],
  ["수학", "#F79C64"],
  ["사회", "#E76B86"],
  ["과학", "#ABC261"],
  ["직업", "#028E93"],
  ["예체능", "#2165A0"],
  ["기타", "#B55096"],
])
