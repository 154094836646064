import React from "react"
import * as styles from "./detailHeader.module.css"

const DetailHeader = props => {
  return (
    <>
      <hr style={{ marginTop: "0px" }} />
      <div className={styles.con}>
        <div style={{ marginRight: "50px" }}>{props.left}</div>
        <div className={styles.rightCon}>{props.right}</div>
      </div>
      <hr />
      <div className={styles.con2}>{props.children}</div>
    </>
  )
}

export default DetailHeader
