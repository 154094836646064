import React, { useEffect, useState, useRef, Fragment, useContext } from "react"
import * as styles from "./comment.module.css"
import { profiles } from "utils/data.js"
import api from "apis"
import { getDateYMD, getJson } from "utils"
import modalContext from "context/modalContext"

import declareIcorn from "images/declareIcorn.png"
import { useToggle } from "hooks"
import { CommentMap } from "class"
const CommentContainer = props => {
  const { post } = props
  const nanum_number = post.nanum_no
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const my_profile = getJson("profile")

  console.log(my_profile)

  const [commentList, setCommentList] = useState({})
  const [commentLength, setCommentLength] = useState(0)
  const [renderSwicth, onRender] = useToggle()

  const onInsert = async () => {
    console.log(2323, commentRef.current.value)
    const result = await api.commentInsert({
      body: {
        nanum_number,
        comment_parent: 0,
        comment_body: commentRef.current.value,
      },
    })
    if (result) {
      commentRef.current.value = ""
      onRender()
    } else {
      alert("댓글내용이 없거나 서버가 원활하지 않습니다.")
    }
  }

  useEffect(() => {
    ;(async () => {
      const List = await api.commentList(nanum_number)
      console.log(4141, List)

      setCommentList(
        List.reduce(
          (initial, data) => {
            const comment = new CommentMap(data)
            comment.nanum_no = post.nanum_no
            comment.loginProfile = my_profile
            comment.setmodalOpen = setmodalOpen
            comment.setModalMain = setModalMain
            comment.onRender = onRender
            comment.init()

            const state = comment.isParent ? "parent" : "children"
            initial[state].push(comment)
            return initial
          },
          {
            parent: [],
            children: [],
          }
        )
      )
      console.log(6464, commentList) //split comment
    })()
    console.log(6464, commentList) //split comment
  }, [props, renderSwicth])

  const commentRef = useRef(null)

  return (
    <div className={styles.con}>
      <div className={styles.title}>댓글</div>
      {commentList.parent?.map((parent, i) => (
        <Fragment key={i}>
          {parent.isDeleted ? (
            <div className={styles.declareMsg}>삭제된 댓글 입니다.</div>
          ) : (
            <Comment
              data={parent}
              key={i}
              style={
                i === 0
                  ? {
                      border: "none",
                    }
                  : null
              }
            />
          )}
          <div className={styles.recommentField}>
            {commentList.children.reduce((init, child, i) => {
              if (child.parent_no === parent.comment_no) {
                init.push(
                  <Comment
                    style={
                      i === 0
                        ? {
                            marginLeft: "55px",
                            width: "955px",
                          }
                        : { marginLeft: "55px", width: "955px" }
                    }
                    data={child}
                    key={i}
                  />
                )
              }
              return init
            }, [])}
          </div>
          {/* {parent.isDeleted ? null : (
            <div className={styles.declareMsg} style={{ paddingLeft: "55px" }}>
              삭제된 메세지 입니다.
            </div>
          )} */}
        </Fragment>
      ))}
      <div className={styles.insert}>
        <img src={profiles.get(my_profile.icon)} alt="" />
        <div>
          <div className={styles.name}>{my_profile.name}</div>
          <textarea
            type="text"
            placeholder={"댓글을 남겨주세요"}
            ref={commentRef}
            maxLength="100"
            onChange={e => setCommentLength(e.currentTarget.value.length)}
          />
        </div>
        <div className={styles.length}>{commentLength}/100</div>
        <div onClick={onInsert} className={styles.insertBtn}>
          등록
        </div>
      </div>
    </div>
  )
}

export default CommentContainer

const Comment = props => {
  const { data } = props
  const [recommentField, setRecommentField] = useState(null)
  const [modField, setModField] = useState(null)

  return modField ? (
    modField
  ) : (
    <>
      <div
        key={data.comment_no}
        className={`${styles.comment} ${modField ? styles.modify : ""}`}
        style={props.style}
      >
        <img src={profiles.get(data.profile.icon_no)} alt="" />
        <div>
          <div data-uuid={data.profile.uuid} className={styles.name}>
            <div>{data.profile.name}</div>
            {data.isMyComment ? (
              <div className={styles.myComment}>작성자</div>
            ) : null}
          </div>
          <div className={styles.commentContent}>
            {data.content.slice(0, 100)}
          </div>
          <div className={styles.commentMenu}>
            <div>
              <div>{data.register?.year}</div>
              <div>{data.register?.hour}</div>
              {data.isParent ? (
                <div
                  style={{ cursor: "pointer", marginBottom: "22px" }}
                  onClick={() => {
                    setRecommentField(
                      <InputState
                        data={data}
                        type={"recommnet"}
                        onCancel={() => setRecommentField(null)}
                        style={props.style}
                        isRecomment={true}
                      />
                    )
                  }}
                >
                  답글쓰기
                </div>
              ) : null}
            </div>
            <div className={styles.controlBtn}>
              {data.isMyComment ? (
                <>
                  <div
                    style={{ marginTop: "5px" }}
                    onClick={() => {
                      setModField(
                        <InputState
                          data={data}
                          type={"modify"}
                          onCancel={() => setModField(null)}
                          style={props.style}
                        />
                      )
                    }}
                  >
                    수정
                  </div>
                  <div
                    onClick={() => data.delete()}
                    style={{ marginTop: "5px" }}
                  >
                    삭제
                  </div>
                </>
              ) : (
                <img
                  src={declareIcorn}
                  onClick={() => {
                    data.setModalMain(<CommentDeclare data={data} />)
                    data.setmodalOpen(true)
                  }}
                />
              )}
            </div>
          </div>
          {recommentField ? recommentField : null}
        </div>
      </div>
    </>
  )
}

const InputState = props => {
  const [commentLength, setCommentLength] = useState(0)
  const ref = useRef(null)
  const { data } = props

  return (
    <div className={styles.insert} style={props.style}>
      <img src={profiles.get(data.loginProfile.icon)} alt="" />
      <div>
        <div className={styles.name}>{data.loginProfile.name}</div>
        <textarea
          type="text"
          placeholder={props.placeholder}
          defaultValue={props.isRecomment ? "" : data.content}
          ref={ref}
          maxLength="100"
          onChange={e => setCommentLength(e.currentTarget.value.length)}
        />
      </div>
      <div className={styles.length}>{commentLength}/100</div>
      <div onClick={props.onCancel} className={styles.insertBtn}>
        취소
      </div>
      <div
        onClick={() => data[props.type](ref.current.value, props.onCancel)}
        className={styles.insertBtn}
      >
        등록
      </div>
    </div>
  )
}

import modalClose from "images/modalClose.png"
import { declareState } from "utils/data.js"
import CheckBox from "components/tools/checkBox.js"
const CommentDeclare = props => {
  const [currentChecked, setCurrentChecked] = useState(null)
  const { data } = props

  return (
    <div className={styles.declareCon}>
      <div className={styles.declareTitle}>
        <div>
          <div>댓글신고</div>
          <div className={styles.dotbox}>
            <div>.</div>
            <div>.</div>
          </div>
        </div>
        <img src={modalClose} onClick={() => data.setmodalOpen(false)} />
      </div>
      <hr className={styles.hr} />
      <div className={styles.declareWriter}>
        <div>댓글 작성자 :</div>
        <div>{data.profile.name}</div>
      </div>
      <hr className={styles.hr} />
      <div>
        <div className={styles.declareReason}>이 댓글을 신고하는 이유</div>
        {Array.from(declareState).map(([key, val]) => (
          <div key={`${key}`} className={styles.declareContents}>
            <CheckBox
              id={`${key}`}
              name={`declare_name`}
              className={"circleCheckBox"}
              onClick={({ target }) => {
                if (currentChecked) currentChecked.checked = false
                setCurrentChecked(target)
              }}
            />
            <div>{val}</div>
          </div>
        ))}
      </div>
      <div
        className={`greenBtn ${styles.declareBtn}`}
        onClick={() => {
          if (currentChecked) data.declare(currentChecked.getAttribute("id"))
        }}
      >
        신고하기
      </div>
    </div>
  )
}
