// extracted by mini-css-extract-plugin
export var con = "comment-module--con--36jJZ";
export var title = "comment-module--title--2-5fH";
export var insert = "comment-module--insert--EJfp1";
export var name = "comment-module--name--2WvAY";
export var insertBtn = "comment-module--insertBtn--3Tc-8";
export var length = "comment-module--length--2cRcc";
export var comment = "comment-module--comment--2-UH5";
export var commentContent = "comment-module--commentContent--3sJAz";
export var commentMenu = "comment-module--commentMenu--RvpWJ";
export var recommentField = "comment-module--recommentField--2taJT";
export var modify = "comment-module--modify--1noL7";
export var controlBtn = "comment-module--controlBtn--1Cy7P";
export var declareMsg = "comment-module--declareMsg--35IGS";
export var myComment = "comment-module--myComment--1QJ0Y";
export var declareCon = "comment-module--declareCon--8XZ62";
export var declareTitle = "comment-module--declareTitle--3-ZXK";
export var dotbox = "comment-module--dotbox--b0Q1Z";
export var declareReason = "comment-module--declareReason--4x11j";
export var declareContents = "comment-module--declareContents--18dAb";
export var declareWriter = "comment-module--declareWriter--3UH88";
export var declareBtn = "comment-module--declareBtn--3Zcud";
export var hr = "comment-module--hr--19XOv";
export var hrr = "comment-module--hrr--1n17y";