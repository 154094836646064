// extracted by mini-css-extract-plugin
export var leftImg = "detail-module--leftImg--14sNJ";
export var curriculum = "detail-module--curriculum--1esyo";
export var main = "detail-module--main--2mbeJ";
export var rightBox = "detail-module--rightBox--dHXhY";
export var title = "detail-module--title--1ES-P";
export var course = "detail-module--course--3tiw4";
export var kind = "detail-module--kind--3I5IY";
export var storyIntro = "detail-module--storyIntro--2MF0y";
export var info = "detail-module--info--1JBgv";
export var user = "detail-module--user--2mPVy";
export var post = "detail-module--post--3Mc0U";
export var view = "detail-module--view--2VyDT";
export var etc = "detail-module--etc--9pzP8";
export var fileCon = "detail-module--fileCon--1dGYd";
export var slideCon = "detail-module--slideCon--2XP0y";
export var slideTitle = "detail-module--slideTitle--1u-zo";
export var Btn = "detail-module--Btn--1uvye";
export var textBody = "detail-module--textBody--1nas2";