import React, { useState } from "react"

const CheckBox = props => {
  const input = (() => {
    const { onClick, onChange } = props

    if (onChange)
      return (
        <input
          type="checkbox"
          id={props.id}
          name={props.name}
          data-id={props.data}
          className={props.className}
          onChange={onChange}
          checked={props.checked ? "checked" : ""}
        />
      )

    return (
      <input
        type="checkbox"
        id={props.id}
        name={props.name}
        data-id={props.data}
        className={props.className}
        onClick={onClick}
        defaultChecked={props.checked ? "checked" : ""}
      />
    )
  })()

  return (
    <>
      {input}
      <label htmlFor={props.id}></label>
    </>
  )
}

export default CheckBox
